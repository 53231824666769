<template>
  <div>
    <page-heading :heading="$t('account')">
      <template v-slot:actions>
        <app-button theme="info" :label="$t('log_out')" @click="logout"></app-button>
      </template>
    </page-heading>

    <loading-wrapper class="max-w-2xl">
      <form-open autocomplete="off" @submit="submit">
        <debug>{{ model }}</debug>
        <div class="grid grid-cols-2 gap-4">
          <form-text :form-id="formId" input-id="first_name" form-error-id="first_name" :label="$t('model.user.first_name')" v-model="model.first_name"/>
          <form-text :form-id="formId" input-id="last_name" form-error-id="last_name" :label="$t('model.user.last_name')" v-model="model.last_name"/>
          <form-text :form-id="formId" input-id="username" form-error-id="username" :label="$t('model.user.username')" v-model="model.username"/>
          <form-text :form-id="formId" input-id="email" form-error-id="email" type="email" :label="$t('model.user.email')" icon="inbox" v-model="model.email" :required="false" :help="$t('email_text')"/>

          <a href="#" class="col-span-full flex items-center text-gray-600 link" @click.prevent="togglePasswordFields">
            <app-svg class="h-5 w-5" :svg="!showPasswordFields ? 'lock-closed' : 'lock-open'"/>
            <span class="ml-2 text-sm">{{ $t('update_password') }}</span>
          </a>

          <form-text :disabled="!showPasswordFields" :form-id="formId" input-id="password" form-error-id="password" type="password" :label="$t('model.user.password')" v-model="model.password"
            :help="$t('password_changed_login')"
          />
          <form-text :disabled="!showPasswordFields" :form-id="formId" input-id="password_confirmation" form-error-id="password_confirmation" type="password" :label="$t('model.user.password_confirmation')" v-model="model.password_confirmation"/>
        </div>

        <div class="flex justify-end">
          <app-button class="mt-4" :label="$t('update')" :disabled="loading" :loading="loading"/>
        </div>
      </form-open>
    </loading-wrapper>
  </div>
</template>

<script>
import ApiAccountService from '@/services/api/account';
import ApiAuthService from '@/services/api/auth';
import Common from '@/mixins/common';
import { SET_USER } from '@/store/mutation-types';
import _ from 'lodash';
import store from '@/store';

export default {
  data() {
    return {
      formId: 'updateAccount',
      model: {},
      showPasswordFields: false,
    };
  },
  metaInfo() {
    return {
      title: this.$t('account'),
    };
  },
  methods: {
    logout() {
      ApiAuthService.logout().then(() => {
        this.$store.commit(`auth/${SET_USER}`, null);
        this.$router.push({
          name: 'login',
        }).catch(() => {});
      });
    },
    modelToRequest() {
      return this.model;
    },
    submit() {
      ApiAccountService.update(this.modelToRequest(), {
        formId: this.formId,
        showMessage: true,
      })
        .then(() => {
          ApiAuthService.user({
            params: {
              include: [
                'organisation',
                'permissions',
              ],
            },
          })
            .then((response) => {
              const { data } = response.data;

              store.commit(`auth/${SET_USER}`, data);
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    togglePasswordFields() {
      this.showPasswordFields = !this.showPasswordFields;

      if (!this.showPasswordFields) {
        this.model.password = null;
        this.model.password_confirmation = null;
      }
    },
  },
  mixins: [
    Common,
  ],
  mounted() {
    ApiAuthService.user().then((response) => {
      const { data } = response.data;

      this.model = _.pick(data, [
        'email',
        'first_name',
        'last_name',
        'username',
      ]);
    });
  },
};
</script>
